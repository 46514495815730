import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "SB4-2023-U4-P36-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page36/E1/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    maxLength: 10,
    textAlign: "center",
    inputSize: 160,
    titleQuestion: [
      {
        num: "1",
        title: "Look. Does she work in an office? What do you see?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page36/E1/1.jpg'/>
        <div>
          What school subjects can you see?<br>#,#,#,#
        </div>
        
        
        `,
        answer: [
          "English / math / art / Vietnamese",
          "English / math / art / Vietnamese",
          "English / math / art / Vietnamese",
          "English / math / art / Vietnamese",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB4-2023-U4-P36-E2",
    audio: "Audios/Page36/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and read. <headphone name='56' src='Audios/Page36/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page36/E1/1.jpg" }]],
  },
  3: {
    unit: "Unit 4",
    id: "SB4-2023-U4-P36-E3",
    audio: "",
    video: "",
    // exerciseKey: "img/FriendsPlus/Page36/E3/Key/answerKey.png",
    component: T6,
    maxLength: 40,
    textAlign: "center",
    inputSize: 250,
    hideBtnFooter:true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read again and answer the question. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img style='height:15cm'  src='img/FriendsPlus/Page36/E1/1.jpg'/>
        <div style='line-height:50px' >
          <b>1</b>&ensp;Do you have a computer room? <u style='color:rgb(25 181 239)' >&ensp;Yes, we do.&ensp;</u>
          </br><b>2</b>&ensp;Is it big or small? #
          </br><b>3</b>&ensp;What do you have here? #
          </br><b>4</b>&ensp;What can you do here? #

        </div>
        `,
        answer: ["", "", ""],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB4-2023-U4-P36-E4",
    audio: "",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "",
    component: T6,
    // maxLength: 7,
    inputSize: 40,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "4",
        title: "What’ your favorite room in your school? Why?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB4-2023-U4-P36-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page36/E5/1.jpg" }]],
  },
};

export default json;
