import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import ScoreByColor from "../../components/ExcerciseTypes/Design/ScoreByColor";
const json = {
  1: {
    unit: "Fluency Time 2",
    id: "SB4-2023-FL2-P53-E1",
    audio: "Audios/Page53/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='82' src='Audios/Page53/tieude-e1.mp3'></headphone>",
        color: "#f15d26",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page53/E1/1.jpg",
          audioUrl: "Audios/Page53/float.mp3",
        },
        {
          url: "img/FriendsPlus/Page53/E1/2.jpg",
          audioUrl: "Audios/Page53/sink.mp3",
        },
        {
          url: "img/FriendsPlus/Page53/E1/3.jpg",
          audioUrl: "Audios/Page53/air.mp3",
        },
        {
          url: "img/FriendsPlus/Page53/E1/4.jpg",
          audioUrl: "Audios/Page53/light.mp3",
        },
        {
          url: "img/FriendsPlus/Page53/E1/5.jpg",
          audioUrl: "Audios/Page53/heavy.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Fluency Time 2",
    id: "SB4-2023-FL2-P53-E2",
    audio: "Audios/Page53/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and sing. <headphone name='83' src='Audios/Page53/tieude-e2.mp3'></headphone>",
        color: "#f15d26",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page53/E2/1.jpg" }]],
  },
  3: {
    unit: "Fluency Time 2",
    id: "SB4-2023-FL2-P53-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page53/E3/Key/answerKey.png",

    titleQuestion: [
      {
        num: "3",
        title:
          "Read and circle a or b. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#f15d26",
      },
    ],

    component: DesignUnderLine,
    // recorder: true,
    question: [],
    totalInput: 2,

    questionImage: [
      [{ url: "img/FriendsPlus/Page53/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page53/E3/2.jpg" },
        { url: "img/FriendsPlus/Page53/E3/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page53/E3/4.jpg" },
        { url: "img/FriendsPlus/Page53/E3/5.jpg", input: 2 },
        { url: "img/FriendsPlus/Page53/E3/6.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page53/E3/7.jpg" },
        { url: "img/FriendsPlus/Page53/E3/8.jpg", input: 1 },
        { url: "img/FriendsPlus/Page53/E3/9.jpg" },
        { url: "img/FriendsPlus/Page53/E3/10.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page53/E3/11.jpg" },
      ],
    ],
  },
  4: {
    unit: "Fluency Time 2",
    id: "SB4-2023-FL2-P53-E4",
    audio: "",
    video: "",
    component: T6,
    recorder: true,
    question: [],
    textareaStyle: {
      width: 800,
    },
    hideBtnFooter:true,
    titleQuestion: [
      {
        num: "4",
        title:
          "<img style='width:25mm' src='img/FriendsPlus/Page11/E3/c.jpg'/> Which objects are light and float? Which objects are heavy and sink? Make a poster. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#f15d26",
      },
    ],
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Fluency Time 2",
    id: "SB4-2023-FL2-P53-E5",
    audio: "",
    video: "",
    titleQuestion: [
      {
        num: "5",
        title: "Look and color.",
        color: "#f15d26",
      },
    ],
    hideBtnFooter: true,
    component: DesignUnderLine,
    // recorder: true,
    question: [],
    totalInput: 1,

    questionImage: [
      [{ url: "img/FriendsPlus/Page53/E5/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page53/E5/2.jpg" },
        {
          url: "img/FriendsPlus/Page53/E5/3.jpg",
          input: 1,
          isCorrect: true,
          audioUrl: "Audios/success-Sound.mp3",
        },
        { url: "img/FriendsPlus/Page53/E5/4.jpg" },
        {
          url: "img/FriendsPlus/Page53/E5/5.jpg",
          input: 1,
          isCorrect: true,
          audioUrl: "Audios/success-Sound.mp3",
        },
        { url: "img/FriendsPlus/Page53/E5/6.jpg" },
        {
          url: "img/FriendsPlus/Page53/E5/7.jpg",
          input: 1,
          isCorrect: true,
          audioUrl: "Audios/success-Sound.mp3",
        },
        { url: "img/FriendsPlus/Page53/E5/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page53/E5/9.jpg" }],
    ],
  },
};

export default json;
