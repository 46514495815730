import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Starter",
    id: "SB4-2023-Starter-P8-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Look at the pictures. Describe the children. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page8/E1/1.jpg",
        },
      ],
    ],
  },
  2: {
    unit: "Starter",
    id: "SB4-2023-Starter-P8-E2",
    audio: "Audios/Page8/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and point. Ask and answer. <headphone name='07' src='Audios/Page8/tieude-e2.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page8/E1/1.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Starter",
    id: "SB4-2023-Starter-P8-E3",
    audio: "",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page8/E3/Key/answerKey.png",
    component: T6,
    maxLength: 5,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 97,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
				<img  src='img/FriendsPlus/Page8/E3/1.jpg'/>
        <div style='display:flex; line-height: 50px'>
        	<div style='margin-right:50px'>
						<b>1</b>&ensp;<u style='color:rgb(94 133 151)' >&ensp;Max&ensp;</u> is twelve.<br>
						<b>2</b>&ensp;#	is Max’s sister.<br>
						<b>3</b>&ensp;#	is Leo’s sister.<br>
						<b>4</b>&ensp;#’s favorite color is green.
					</div>
        	<div>
						<b>5</b>&ensp;#	has short hair and brown eyes.<br>
						<b>6</b>&ensp;#’ favorite color is blue.<br>
						<b>7</b>&ensp;#	is Amy’s brother.<br>
						<b>8</b>&ensp;Max and#are Amy’s cousins.
					</div>
        	
				</div>
        
        `,
        answer: ["Holly", "Amy", "Leo", "Max", "Amy", "Leo", "Holly"],
      },
    ],
  },
};

export default json;
