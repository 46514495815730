import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB4-2023-U1-P14-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title: "Look at the pictures. Say the jobs.",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page14/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page14/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page14/E1/3.jpg",
          audioUrl: "Audios/Page14/E2/Mai.mp3",
        },
        { url: "img/FriendsPlus/Page14/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page14/E1/5.jpg",
          audioUrl: "Audios/Page14/E2/dad.mp3",
        },
        { url: "img/FriendsPlus/Page14/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page14/E1/7.jpg",
          audioUrl: "Audios/Page14/E2/mom.mp3",
        },
        { url: "img/FriendsPlus/Page14/E1/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E1/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page14/E1/10.jpg" },
        {
          url: "img/FriendsPlus/Page14/E1/11.jpg",
          audioUrl: "Audios/Page14/E2/grandpa.mp3",
        },
        { url: "img/FriendsPlus/Page14/E1/12.jpg" },
        {
          url: "img/FriendsPlus/Page14/E1/13.jpg",
          audioUrl: "Audios/Page14/E2/Minh.mp3",
        },
        { url: "img/FriendsPlus/Page14/E1/14.jpg" },
        {
          url: "img/FriendsPlus/Page14/E1/15.jpg",
          audioUrl: "Audios/Page14/E2/family.mp3",
        },
        { url: "img/FriendsPlus/Page14/E1/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E1/17.jpg" }],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB4-2023-U1-P14-E2",
    audio: "Audios/Page14/E2/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and read. <headphone name='17' src='Audios/Page14/E2/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page14/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page14/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page14/E1/3.jpg",
          audioUrl: "Audios/Page14/E2/Mai.mp3",
        },
        { url: "img/FriendsPlus/Page14/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page14/E1/5.jpg",
          audioUrl: "Audios/Page14/E2/dad.mp3",
        },
        { url: "img/FriendsPlus/Page14/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page14/E1/7.jpg",
          audioUrl: "Audios/Page14/E2/mom.mp3",
        },
        { url: "img/FriendsPlus/Page14/E1/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E1/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page14/E1/10.jpg" },
        {
          url: "img/FriendsPlus/Page14/E1/11.jpg",
          audioUrl: "Audios/Page14/E2/grandpa.mp3",
        },
        { url: "img/FriendsPlus/Page14/E1/12.jpg" },
        {
          url: "img/FriendsPlus/Page14/E1/13.jpg",
          audioUrl: "Audios/Page14/E2/Minh.mp3",
        },
        { url: "img/FriendsPlus/Page14/E1/14.jpg" },
        {
          url: "img/FriendsPlus/Page14/E1/15.jpg",
          audioUrl: "Audios/Page14/E2/family.mp3",
        },
        { url: "img/FriendsPlus/Page14/E1/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E1/17.jpg" }],
    ],
  },

  3: {
    unit: "Unit 1",
    id: "SB4-2023-U1-P14-E3",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page14/E3/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    inputSize: 35,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read and write T true or F false. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex; line-height:56px'>
          <div>
            <b>1.</b> Mai’s parents are pilots. #<br>
            <b>2.</b> Her mother is an office worker. #
          </div>
          <div style='margin-left:50px'>
            <b>3.</b> Her grandpa is a pilot. #<br>
            <b>4.</b> Mai and Minh study at school. #
          </div>
          
        </div>
        
        `,
        answer: ["F", "T", "F", "T"],
      },
    ],
  },
};

export default json;
