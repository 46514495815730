import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 10",
    id: "SB4-2023-U10-P76-E1",
    audio: "Audios/Page76/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='120' src='Audios/Page76/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page76/E1/1.jpg",
          audioUrl: "Audios/Page76/read-comics.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/2.jpg",
          audioUrl: "Audios/Page76/play-chess.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/3.jpg",
          audioUrl: "Audios/Page76/fish.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/4.jpg",
          audioUrl: "Audios/Page76/play-basketball.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/5.jpg",
          audioUrl: "Audios/Page76/play-volleyball.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/6.jpg",
          audioUrl: "Audios/Page76/play-badminton.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 10",
    id: "SB4-2023-U10-P76-E2",
    audio: "Audios/Page76/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='121' src='Audios/Page76/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page76/E1/1.jpg",
          audioUrl: "Audios/Page76/read-comics.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/2.jpg",
          audioUrl: "Audios/Page76/play-chess.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/3.jpg",
          audioUrl: "Audios/Page76/fish.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/4.jpg",
          audioUrl: "Audios/Page76/play-basketball.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/5.jpg",
          audioUrl: "Audios/Page76/play-volleyball.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E1/6.jpg",
          audioUrl: "Audios/Page76/play-badminton.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 10",
    id: "SB4-2023-U10-P76-E3",
    audio: "Audios/Page76/audio-e3.mp3",
    video: "Videos/Unit 10.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='122' src='Audios/Page76/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page76/E3/1.jpg",
          audioUrl: "Audios/Page76/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E3/2.jpg",
          audioUrl: "Audios/Page76/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page76/E3/3.jpg",
          audioUrl: "Audios/Page76/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page76/E3/4.jpg",
          audioUrl: "Audios/Page76/hinh4.mp3",
        },
      ],
    ],
  },
};

export default json;
