import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P45-E1",
    audio: "Audios/Page44/audio-e3.mp3",
    video: "Videos/Unit 06.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='71' src='Audios/Page44/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page44/E3/1.jpg",
          audioUrl: "Audios/Page44/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E3/2.jpg",
          audioUrl: "Audios/Page44/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page44/E3/3.jpg",
          audioUrl: "Audios/Page44/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page44/E3/4.jpg",
          audioUrl: "Audios/Page44/hinh4.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P45-E2",
    audio: "Audios/Page45/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='72' src='Audios/Page45/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {  url: "img/FriendsPlus/Page45/E2/1.jpg" },],[
        {  url: "img/FriendsPlus/Page45/E2/2.jpg" },
        {  url: "img/FriendsPlus/Page45/E2/3.jpg",audioUrl: "Audios/Page45/hinh1.mp3" },
        {  url: "img/FriendsPlus/Page45/E2/4.jpg" },],[
        {  url: "img/FriendsPlus/Page45/E2/5.jpg" },
        {  url: "img/FriendsPlus/Page45/E2/6.jpg",audioUrl: "Audios/Page45/hinh2.mp3" },
        {  url: "img/FriendsPlus/Page45/E2/7.jpg" },],[
        {  url: "img/FriendsPlus/Page45/E2/8.jpg" },
        {  url: "img/FriendsPlus/Page45/E2/9.jpg",audioUrl: "Audios/Page45/hinh3.mp3" },
        { url: "img/FriendsPlus/Page45/E2/10.jpg" },],[
        { url: "img/FriendsPlus/Page45/E2/11.jpg" },
        { url: "img/FriendsPlus/Page45/E2/12.jpg",audioUrl: "Audios/Page45/hinh4.mp3" },
        { url: "img/FriendsPlus/Page45/E2/13.jpg" },],[
        { url: "img/FriendsPlus/Page45/E2/14.jpg" },
      ]
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P45-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page45/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Read and circle  a or b.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    component: DesignUnderLine,
    // recorder: true,
    question: [],
    // isHiddenCheck: true,
    totalInput: 4,

    questionImage: [
      [{ url: "img/FriendsPlus/Page45/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page45/E3/2.jpg" },
        { url: "img/FriendsPlus/Page45/E3/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page45/E3/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page45/E3/5.jpg" },
        { url: "img/FriendsPlus/Page45/E3/6.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page45/E3/7.jpg" },
        { url: "img/FriendsPlus/Page45/E3/8.jpg", input: 2 },
        { url: "img/FriendsPlus/Page45/E3/9.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page45/E3/10.jpg" },
        { url: "img/FriendsPlus/Page45/E3/11.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page45/E3/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page45/E3/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page45/E3/14.jpg" },
        { url: "img/FriendsPlus/Page45/E3/15.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page45/E3/16.jpg" },
        { url: "img/FriendsPlus/Page45/E3/17.jpg", input: 4 },
        { url: "img/FriendsPlus/Page45/E3/18.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page45/E3/19.jpg" },
        { url: "img/FriendsPlus/Page45/E3/20.jpg", input: 3 },
        { url: "img/FriendsPlus/Page45/E3/21.jpg" },
        { url: "img/FriendsPlus/Page45/E3/22.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page45/E3/23.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page45/E3/24.jpg" }],
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P45-E4",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page45/E4/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    maxLength: 13,

    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 200,
    titleQuestion: [
      {
        num: "4",
        title:
          "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex'>
          <div>
            <img  src='img/FriendsPlus/Page45/E4/1.jpg'/>
            <div style='width:40%'>
              <b>1</b>&ensp;<u style='color:rgb(25 181 239)' >&ensp;What is he&ensp;</u>doing?<u style='color:rgb(25 181 239)' >&ensp;He’s&ensp;</u>writing.      
            </div>
            <img  src='img/FriendsPlus/Page45/E4/3.jpg'/>
            <div style='width:50%'>
              <b>2</b>&ensp;#playing?<input id='1' width='140px' ></input>playing chess.
            </div>
          </div>
          
          <div >
            <img  src='img/FriendsPlus/Page45/E4/2.jpg'/>
            <div style='width:50%'>
              <b>3</b>&ensp;#doing?<input id='3' width='140px' ></input>playing with a ball.      
            </div>
            <img  src='img/FriendsPlus/Page45/E4/4.jpg'/>
            <div style='width:50%'>
              <b>4</b>&ensp;#doing?<input id='5' width='110px' ></input>cooking.
            </div>
          </div>
        </div>


        
        `,
        answer: [
          "What are they",
          "They're / They are",
          "What are they",
          "They're / They are",
          "What is Mom",
          "She's / She is",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P45-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page45/E5/1.jpg" }]],
  },
};

export default json;
