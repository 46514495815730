import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 1",
    id: "SB4-2023-U1-P12-E1",
    audio: "Audios/Page12/E1/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='12' src='Audios/Page12/E1/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page12/E1/1.jpg",
        },
        {
          url: "img/FriendsPlus/Page12/E1/2.jpg",
          audioUrl:
            "Audios/Page12/E1/police-officer-police-officers-help-veryone.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/3.jpg",
        },
        {
          url: "img/FriendsPlus/Page12/E1/4.jpg",
          audioUrl: "Audios/Page12/E1/farmer-farmers-grow-food.mp3",
        },
        {
          url: "img/FriendsPlus/Page12/E1/5.jpg",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB4-2023-U1-P12-E2",
    audio: "Audios/Page12/E2/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and sing. <headphone name='13' src='Audios/Page12/E2/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page12/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 1",
    id: "SB4-2023-U1-P12-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title: "Sing and do.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page12/E2/1.jpg" }]],
  },
};

export default json;
