import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "SB4-2023-U5-P41-E1",
    audio: "Audios/Page41/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='64' src='Audios/Page41/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page41/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page41/E1/2.jpg",
          audioUrl: "Audios/Page41/web.mp3",
        },
        {
          url: "img/FriendsPlus/Page41/E1/3.jpg",
          audioUrl: "Audios/Page41/window.mp3",
        },
        {
          url: "img/FriendsPlus/Page41/E1/4.jpg",
          audioUrl: "Audios/Page41/walk.mp3",
        },
        { url: "img/FriendsPlus/Page41/E1/5.jpg" },
        {
          url: "img/FriendsPlus/Page41/E1/6.jpg",
          audioUrl: "Audios/Page41/box.mp3",
        },
        {
          url: "img/FriendsPlus/Page41/E1/7.jpg",
          audioUrl: "Audios/Page41/fox.mp3",
        },
        {
          url: "img/FriendsPlus/Page41/E1/8.jpg",
          audioUrl: "Audios/Page41/six.mp3",
        },
        { url: "img/FriendsPlus/Page41/E1/9.jpg" },
      ],
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB4-2023-U5-P41-E2",
    audio: "Audios/Page41/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='65' src='Audios/Page41/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page41/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 5",
    id: "SB4-2023-U5-P41-E3",
    audio: "",
    video: "",
    isHiddenCheck: true,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page41/E3/Key/answerKey.png",
    component: T6,
    maxLength: 6,
    inputSize: 120,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the chant again. Say the words with w and x. Write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img  src='img/FriendsPlus/Page41/E2/1.jpg'/>
        <div style='text-align:center'>#,#,#,#</div>
        
        `,
        answer: [
          "window / box / web / fox",
          "window / box / web / fox",
          "window / box / web / fox",
          "window / box / web / fox",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB4-2023-U5-P41-E4",
    audio: "Audios/Page41/audio-e4.mp3",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page41/E4/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    textAlign: "center",
    inputSize: 40,
    titleQuestion: [
      {
        num: "4",
        title:
          "Listen and complete the words. <headphone name='66' src='Audios/Page41/tieude-e4.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;'>
          <img style='height:37mm' src='img/FriendsPlus/Page41/E4/1.jpg'/>
            
            <div style='margin: 0px 30px;text-align:center'>
              <img  src='img/FriendsPlus/Page41/E4/2.jpg'/>
              <div>
                bo<u style='color:rgb(94 133 151)' >&ensp;x&ensp;</u>       
              </div>
            </div>
            <div  style='text-align:center'>
              <img  src='img/FriendsPlus/Page41/E4/3.jpg'/>
              <div>
                  #eb
              </div>
            </div>
        </div>
        <div style='display:flex'>
            <div style='text-align:center'>
              <img  src='img/FriendsPlus/Page41/E4/4.jpg'/>
              <div>
                si# 
              </div>
            </div>
            <div style='margin: 0px 30px;text-align:center'>
              <img  src='img/FriendsPlus/Page41/E4/5.jpg'/>
              <div>
                  #alk     
              </div>
            </div>
            <div  style='text-align:center'>
              <img  src='img/FriendsPlus/Page41/E4/6.jpg'/>
              <div>
                  fo#      
              </div>
            </div>
        </div>
        
        `,
        answer: ["w", "x", "w", "x"],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB4-2023-U5-P41-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page41/E5/1.jpg" }]],
  },
};

export default json;
