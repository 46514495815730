import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P86-E1",
    audio: "Audios/Page86/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and read. Who is having a party? <headphone name='139' src='Audios/Page86/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page86/E1/1.jpg" }]],
  },
  2: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P86-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page86/E2/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    textAlign: "center",
    inputSize: 40,
    titleQuestion: [
      {
        num: "2",
        title:
          "Read again and write T (true) or F (false). <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:Flex;width:27cm;line-height:50px'>
          <img style='margin-left:-50px;height:15cm'  src='img/FriendsPlus/Page86/E1/1.jpg'/>
          <div  >
            <b>1.</b>&ensp;Vinh is having a party. <u style='color:rgb(25 181 239)' >&ensp;T&ensp;</u><br>
            <b>2.</b>&ensp;The party is on March 22. #<br>
            <b>3.</b>&ensp;The party at a café. #<br>
            <b>4.</b>&ensp;Turn right at the school. #<br>
            <b>5.</b>&ensp;Vinh’s house is opposite the police station. #<br>

          </div>
        </div>
        `,
        answer: ["F", "F", "F", "T"],
      },
    ],
  },
  3: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P86-E3",
    audio: "",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "",
    component: T6,
    // maxLength: 7,
    inputSize: 40,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "3",
        title:
          "Give directions to your house from school. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `

        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P86-E1",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page86/E5/1.jpg" }]],
  },
};

export default json;
