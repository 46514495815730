import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P46-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "What are they doing? Ask and answer. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page46/E1/1.jpg",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P46-E2",
    audio: "",
    video: "",
    component: T6,
    checkUppercase: true,
    maxLength: 13,
    hideBtnFooter:true,
    textareaStyle: {
      width: 700,
    },
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 200,
    titleQuestion: [
      {
        num: "2",
        title:
          "Write about the girls.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
       <div>
        <span style='color:rgb(41 185 230);font-style:italic'>Thanh and Kim are talking photos, Mai and Ly are ...</span>
        <div><textarea ></textarea></div>
       </div>


        
        `,
        answer: [
          "What are they",
          "They're / They are",
          "What are they",
          "They're / They are",
          "What is Mom",
          "She's / She is",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P46-E4",
    audio: "Audios/Page46/audio-e3.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and sing. <headphone name='73' src='Audios/Page46/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page46/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P46-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "4",
        title: "Sing and do.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page46/E3/1.jpg" }]],
  },
};

export default json;
