import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 3",
    id: "SB4-2023-U3-P25-E1",
    audio: "Audios/Page25/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,

    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='36' src='Audios/Page25/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page25/E1/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page25/E1/2.jpg",
          audioUrl: "Audios/Page25/queen.mp3",
        },
        {
          url: "img/FriendsPlus/Page25/E1/3.jpg",
        },
        {
          url: "img/FriendsPlus/Page25/E1/4.jpg",
          audioUrl: "Audios/Page25/rabbit.mp3",
        },
        {
          url: "img/FriendsPlus/Page25/E1/5.jpg",
        },
        {
          url: "img/FriendsPlus/Page25/E1/6.jpg",
          audioUrl: "Audios/Page25/sofar.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 3",
    id: "SB4-2023-U3-P25-E2",
    audio: "Audios/Page25/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and sing. <headphone name='37' src='Audios/Page25/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page25/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 3",
    id: "SB4-2023-U3-P25-E3",
    audio: "",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page25/E3/Key/answerKey.png",
    component: T6,
    maxLength: 8,
    textAlign: "center",
    inputSize: 120,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the chant again. Say the words with q, r, and s. Write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img  src='img/FriendsPlus/Page25/E2/1.jpg'/>
        <div style=''>#,#,#,#,#,#,#,#</div>
        
        `,
        answer: [
          "queen",
          "question",
          "socks",
          "rabbit",
          "sofa",
          "says",
          "right",
          "rocks",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "SB4-2023-U3-P25-E4",
    audio: "Audios/Page25/audio-e4.mp3",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page25/E4/Key/answerKey.png",
    component: D1,
    maxLength: 1,
    titleQuestion: [
      {
        num: "4",
        title:
          "Listen to the sounds and write the letters. <headphone name='38' src='Audios/Page25/tieude-e4.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page25/E4/1.jpg" },
        {
          url: "img/FriendsPlus/Page25/E4/2.jpg",
          audioUrl: "Audios/Page25/question.mp3",
        },
        { url: "img/FriendsPlus/Page25/E4/3.jpg" },
        {
          url: "img/FriendsPlus/Page25/E4/4.jpg",
          audioUrl: "Audios/Page25/rock.mp3",
        },
        { url: "img/FriendsPlus/Page25/E4/5.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page25/E4/6.jpg" },
        {
          url: "img/FriendsPlus/Page25/E4/7.jpg",
          audioUrl: "Audios/Page25/question.mp3",
        },
        { url: "img/FriendsPlus/Page25/E4/8.jpg" },
        { url: "img/FriendsPlus/Page25/E4/9.jpg" },
        {
          url: "img/FriendsPlus/Page25/E4/10.jpg",
          audioUrl: "Audios/Page25/rock.mp3",
        },
        { url: "img/FriendsPlus/Page25/E4/11.jpg", input: true, answer: "r" },
      ],
      [{ url: "img/FriendsPlus/Page25/E4/12.jpg" }],
      [
        { url: "img/FriendsPlus/Page25/E4/13.jpg" },
        {
          url: "img/FriendsPlus/Page25/E4/14.jpg",
          audioUrl: "Audios/Page25/sock.mp3",
        },
        { url: "img/FriendsPlus/Page25/E4/15.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page25/E4/16.jpg" },
        {
          url: "img/FriendsPlus/Page25/E4/17.jpg",
          audioUrl: "Audios/Page25/sock.mp3",
        },
        { url: "img/FriendsPlus/Page25/E4/18.jpg", input: true, answer: "s" },
        { url: "img/FriendsPlus/Page25/E4/19.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page25/E4/20.jpg" },
        {
          url: "img/FriendsPlus/Page25/E4/21.jpg",
          audioUrl: "Audios/Page25/sock.mp3",
        },
        { url: "img/FriendsPlus/Page25/E4/22.jpg" },
      ],
    ],
  },
  5: {
    unit: "Unit 3",
    id: "SB4-2023-U3-P25-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page25/E5/1.jpg" }]],
  },
};

export default json;
