import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P82-E1",
    audio: "Audios/Page82/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='130' src='Audios/Page82/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page82/E1/1.jpg",
          audioUrl: "Audios/Page82/next-to.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/2.jpg",
          audioUrl: "Audios/Page82/opposite.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/3.jpg",
          audioUrl: "Audios/Page82/between.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/4.jpg",
          audioUrl: "Audios/Page82/turn-left.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/5.jpg",
          audioUrl: "Audios/Page82/turn-right.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/6.jpg",
          audioUrl: "Audios/Page82/go-straght.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P82-E2",
    audio: "Audios/Page82/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='131' src='Audios/Page82/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page82/E1/1.jpg",
          audioUrl: "Audios/Page82/next-to.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/2.jpg",
          audioUrl: "Audios/Page82/opposite.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/3.jpg",
          audioUrl: "Audios/Page82/between.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/4.jpg",
          audioUrl: "Audios/Page82/turn-left.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/5.jpg",
          audioUrl: "Audios/Page82/turn-right.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E1/6.jpg",
          audioUrl: "Audios/Page82/go-straght.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P82-E3",
    audio: "Audios/Page82/audio-e3.mp3",
    video: "Videos/Unit 11.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='132' src='Audios/Page82/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page82/E3/1.jpg",
          audioUrl: "Audios/Page82/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E3/2.jpg",
          audioUrl: "Audios/Page82/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page82/E3/3.jpg",
          audioUrl: "Audios/Page82/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page82/E3/4.jpg",
          audioUrl: "Audios/Page82/hinh4.mp3",
        },
      ],
    ],
  },
};

export default json;
