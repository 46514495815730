import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 1",
    id: "SB4-2023-U1-P10-E1",
    audio: "Audios/Page10/E1/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='08' src='Audios/Page10/E1/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page10/E1/1.jpg",
          audioUrl: "Audios/Page10/E1/doctor.mp3",
        },
        { url: "img/FriendsPlus/Page10/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page10/E1/3.jpg",
          audioUrl: "Audios/Page10/E1/pilot.mp3",
        },
        { url: "img/FriendsPlus/Page10/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page10/E1/5.jpg",
          audioUrl: "Audios/Page10/E1/firefighter.mp3",
        },
        { url: "img/FriendsPlus/Page10/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page10/E1/7.jpg",
          audioUrl: "Audios/Page10/E1/student.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E1/8.jpg",
        },
        {
          url: "img/FriendsPlus/Page10/E1/9.jpg",
          audioUrl: "Audios/Page10/E1/teacher.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB4-2023-U1-P10-E2",
    audio: "Audios/Page10/E2/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='09' src='Audios/Page10/E2/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page10/E1/1.jpg",
          audioUrl: "Audios/Page10/E1/doctor.mp3",
        },
        { url: "img/FriendsPlus/Page10/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page10/E1/3.jpg",
          audioUrl: "Audios/Page10/E1/pilot.mp3",
        },
        { url: "img/FriendsPlus/Page10/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page10/E1/5.jpg",
          audioUrl: "Audios/Page10/E1/firefighter.mp3",
        },
        { url: "img/FriendsPlus/Page10/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page10/E1/7.jpg",
          audioUrl: "Audios/Page10/E1/student.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E1/8.jpg",
        },
        {
          url: "img/FriendsPlus/Page10/E1/9.jpg",
          audioUrl: "Audios/Page10/E1/teacher.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB4-2023-U1-P10-E3",
    audio: "Audios/Page10/E3/audio-e3.mp3",
    video: "Videos/Unit 01.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='10' src='Audios/Page10/E3/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page10/E3/1.jpg",
          audioUrl: "Audios/Page10/E3/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E3/2.jpg",
          audioUrl: "Audios/Page10/E3/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page10/E3/3.jpg",
          audioUrl: "Audios/Page10/E3/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E3/4.jpg",
          audioUrl: "Audios/Page10/E3/hinh4.mp3",
        },
      ],
    ],
  },
};

export default json;
