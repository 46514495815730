import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import ScoreByColor from "../../components/ExcerciseTypes/Design/ScoreByColor";
const json = {
  1: {
    unit: "Fluency Time! 4",
    id: "SB4-2023-FT4-P97-E1",
    audio: "Audios/Page97/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='154' src='Audios/Page97/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page97/E1/1.jpg",
          audioUrl: "Audios/Page97/volcano.mp3",
        },
        {
          url: "img/FriendsPlus/Page97/E1/2.jpg",
          audioUrl: "Audios/Page97/inside.mp3",
        },
        {
          url: "img/FriendsPlus/Page97/E1/3.jpg",
          audioUrl: "Audios/Page97/hole.mp3",
        },
        {
          url: "img/FriendsPlus/Page97/E1/4.jpg",
          audioUrl: "Audios/Page97/erupt.mp3",
        },
        {
          url: "img/FriendsPlus/Page97/E1/5.jpg",
          audioUrl: "Audios/Page97/lava.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Fluency Time! 4",
    id: "SB4-2023-FT4-P97-E2",
    audio: "Audios/Page97/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and read. <headphone name='155' src='Audios/Page97/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page97/E2/1.jpg" }]],
  },
  3: {
    unit: "Fluency Time! 4",
    id: "SB4-2023-FT4-P97-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page97/E3/Key/answerKey.png",
    maxLength: 1,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read again and write T (true) or F (false). <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    inputSize: 40,
    component: T6,
    // isHiddenCheck: true,

    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
          <img style='width:100%' src='img/FriendsPlus/Page97/E2/1.jpg'/>
          <div style='display:flex; justify-content:space-around'>
            <div>
                <b>1.</b>&ensp;Lava is red and yellow. <u style='color:rgb(25 181 239)' >&ensp;T&ensp;</u><br>
                <b>2.</b>&ensp;It is hot inside the volcano. #<br>
            </div>
            <div>
                <b>3.</b>&ensp;Volcanoes can’t erupt. #<br>
                <b>4.</b>&ensp;Mauna Loa is a little volcano. #<br>
            </div>
          </div>

          
          
        
        `,
        answer: ["T", "F", "F"],
      },
    ],
  },
  4: {
    unit: "Fluency Time! 4",
    id: "SB4-2023-FT4-P97-E4",
    audio: "",
    video: "",
    component: T6,
    recorder: true,
    question: [],
    hideBtnFooter:true,
    textareaStyle: {
      width: 800,
    },
    titleQuestion: [
      {
        num: "4",
        title:
          "<img style='width:25mm' src='img/FriendsPlus/Page11/E3/c.jpg'/> Which objects are light and float? Which objects are heavy and sink? Make a poster. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Fluency Time! 4",
    id: "SB4-2023-FT4-P97-E5",
    audio: "",
    video: "",
    titleQuestion: [
      {
        num: "5",
        title: "Look and color.",
        color: "#203c8f",
      },
    ],
    hideBtnFooter: true,
    component: DesignUnderLine,
    // recorder: true,
    question: [],
    totalInput: 1,

    questionImage: [
      [{ url: "img/FriendsPlus/Page97/E5/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page97/E5/2.jpg" },
        {
          url: "img/FriendsPlus/Page97/E5/3.jpg",
          input: 1,
          isCorrect: true,
          audioUrl: "Audios/success-Sound.mp3",
        },
        { url: "img/FriendsPlus/Page97/E5/4.jpg" },
        {
          url: "img/FriendsPlus/Page97/E5/5.jpg",
          input: 1,
          isCorrect: true,
          audioUrl: "Audios/success-Sound.mp3",
        },
        { url: "img/FriendsPlus/Page97/E5/6.jpg" },
        {
          url: "img/FriendsPlus/Page97/E5/7.jpg",
          input: 1,
          isCorrect: true,
          audioUrl: "Audios/success-Sound.mp3",
        },
        { url: "img/FriendsPlus/Page97/E5/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page97/E5/9.jpg" }],
    ],
  },
};

export default json;
