import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Fluency Time! 2",
    id: "SB4-2023-FT2-P52-E1",
    audio: "Audios/Page52/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen. Read and say. <headphone name='80' src='Audios/Page52/tieude-e1.mp3'></headphone>",
        color: "#f15d26",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page52/E1/1.jpg",
          audioUrl: "Audios/Page52/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page52/E1/2.jpg",
          audioUrl: "Audios/Page52/hinh2.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Fluency Time! 2",
    id: "SB4-2023-FT2-P52-E2",
    audio: "Audios/Page52/audio-e2.mp3",
    video: "",
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and write. <headphone name='81' src='Audios/Page52/tieude-e2.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#f15d26",
      },
    ],
    exerciseKey: "img/FriendsPlus/Page52/E2/Key/answerKey.png",
    component: T6,
    checkUpperCase:true,
    isHiddenCheck: true,
    maxLength: 10,
    stylesTextInput: {
      height: "20px",
      // borderRadius: "5px",
      borderBottom: "dash",
      fontSize: "23px",
    },
    inputSize: 157,

    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative;margin-left:20px'>
            <img style='width:90%' src='img/FriendsPlus/Page52/E2/1.jpg'/>
            <span style='position: absolute; top: 106px; left: 630px'><input id='0' width='53px'></input></span>
            <span style='position: absolute; top: 130px; left: 759px'>#.</span>
            <span style='position: absolute; top: 200px; left: 160px'><input id='2' width='115px'></span>
            <span style='position: absolute; top: 212px; left: 734px'><input id='3' width='67px'></span>
            
          </div>
          
        
        `,
        answer: ["No", "Wednesdays", "tomorrow", "Let’s"],
      },
    ],
  },
  3: {
    unit: "Fluency Time! 2",
    id: "SB4-2023-FT2-P52-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Look at the words. Ask and answer. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#f15d26",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page52/E3/1.jpg",
        },
      ],
    ],
  },
};

export default json;
