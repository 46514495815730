import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 12",
    id: "SB4-2023-U12-P92-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page92/E1/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    // maxLength: 5,
    textAlign: "center",
    textareaStyle: { width: 700 },
    inputSize: 100,
    titleQuestion: [
      {
        num: "1",
        title: "Look at the picture. What are the children wearing?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page92/E1/1.jpg'/>
        <div style='text-align:center'>
            <textarea id='0' rows='3'></textarea >
        </div>
        
        
        `,
        answer: [
          "One girl is wearing a red sweater, a skirt, socks, shoes, and a coat. Another girl is wearing a purple dress and green slippers. A boy is wearing a wolf’s costume. ",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 12",
    id: "SB4-2023-U12-P92-E2",
    audio: "Audios/Page92/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and read. <headphone name='149' src='Audios/Page92/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page92/E1/1.jpg" }]],
  },
  3: {
    unit: "Unit 12",
    id: "SB4-2023-U12-P92-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page92/E3/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    textAlign: "center",
    inputSize: 40,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read again and write T(true) or F(false). <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
        
        <div style='display:flex' >
          <img   src='img/FriendsPlus/Page92/E1/1.jpg'/>
        </div>
        <div style='display:flex; justify-content:space-around;'>
          <div>
            <b>1.</b> The play was on Tuesday. <u style='color:rgb(25 181 239)' >&ensp;F&ensp;</u> <br> 
            <b>2.</b> Nam was the wolf in the play. # <br> 
            <b>3.</b> Xuan’s costume was blue. #<br>
            <b>4.</b> The children like acting stories English. #<br>
            <b>5.</b> Mrs.Chi is the art teacher. #
          </div>
        </div>
           
            
            
        `,
        answer: ["T", "F", "T", "F"],
      },
    ],
  },
  4: {
    unit: "Unit 12",
    id: "SB4-2023-U12-P92-E4",
    audio: "",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "",
    component: T6,
    // maxLength: 7,
    inputSize: 40,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "4",
        title: "What plays do you know?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='3'></textarea>
        </div>  
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 12",
    id: "SB4-2023-U12-P92-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page92/E5/1.jpg" }]],
  },
};

export default json;
