import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import ScoreByColor from "../../components/ExcerciseTypes/Design/ScoreByColor";
const json = {
  1: {
    unit: "Fluency Time 3",
    id: "SB4-2023-FT3-P75-E1",
    audio: "Audios/Page75/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='118' src='Audios/Page75/tieude-e1.mp3'></headphone>",
        color: "#ed222c",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page75/E1/1.jpg",
          audioUrl: "Audios/Page75/survey.mp3",
        },
        { url: "img/FriendsPlus/Page75/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page75/E1/3.jpg",
          audioUrl: "Audios/Page75/bar-chart.mp3",
        },
        { url: "img/FriendsPlus/Page75/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page75/E1/5.jpg",
          audioUrl: "Audios/Page75/row.mp3",
        },
        { url: "img/FriendsPlus/Page75/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page75/E1/7.jpg",
          audioUrl: "Audios/Page75/column.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Fluency Time 3",
    id: "SB4-2023-FT3-P75-E2",
    audio: "Audios/Page75/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and sing. <headphone name='119' src='Audios/Page75/tieude-e2.mp3'></headphone>",
        color: "#ed222c",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page75/E2/1.jpg" }]],
  },
  3: {
    unit: "Fluency Time 3",
    id: "SB4-2023-FT3-P75-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page75/E3/Key/answerKey.png",

    titleQuestion: [
      {
        num: "3",
        title:
          "Read again and write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#ed222c",
      },
    ],

    component: T6,
    textAlign: "center",
    inputSize: 200,
    maxLength: 11,

    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img  src='img/FriendsPlus/Page75/E3/1.jpg'/>
        <div style='line-height:50px'>
            <b>1.</b>&ensp;Draw a <u style='color:rgb(25 181 239)' >&ensp;table&ensp;</u>on paper with rows and #.<br>
            <b>2.</b>&ensp;Ask your friends and#the times on the table.<br>
            <b>3.</b>&ensp;Make a#with a different color for each column.<br>
            <b>4.</b>&ensp;The bar chart shows the#about bedtimes.<br>
            
        </div>
        `,
        answer: ["columns", "check", "bar chart", "information"],
      },
    ],
  },
  4: {
    unit: "Fluency Time 3",
    id: "SB4-2023-FT3-P75-E4",
    audio: "",
    video: "",
    component: T6,
    recorder: true,
    question: [],

    hideBtnFooter:true,

    textareaStyle: {
      width: 800,
    },
    titleQuestion: [
      {
        num: "4",
        title:
          "<img style='width:25mm' src='img/FriendsPlus/Page11/E3/c.jpg'/> Do the bedtimes survey in <span style='color:rgb(237 34 44)'>2</span> and make your bar chart.",
        color: "#ed222c",
      },
    ],
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Fluency Time 3",
    id: "SB4-2023-FT3-P75-E5",
    audio: "",
    video: "",
    titleQuestion: [
      {
        num: "5",
        title: "Look and color.",
        color: "#ed222c",
      },
    ],
    hideBtnFooter: true,
    component: DesignUnderLine,
    // recorder: true,
    question: [],
    totalInput: 1,

    questionImage: [
      [{ url: "img/FriendsPlus/Page75/E5/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page75/E5/2.jpg" },
        {
          url: "img/FriendsPlus/Page75/E5/3.jpg",
          input: 1,
          isCorrect: true,
          audioUrl: "Audios/success-Sound.mp3",
        },
        { url: "img/FriendsPlus/Page75/E5/4.jpg" },
        {
          url: "img/FriendsPlus/Page75/E5/5.jpg",
          input: 1,
          isCorrect: true,
          audioUrl: "Audios/success-Sound.mp3",
        },
        { url: "img/FriendsPlus/Page75/E5/6.jpg" },
        {
          url: "img/FriendsPlus/Page75/E5/7.jpg",
          input: 1,
          isCorrect: true,
          audioUrl: "Audios/success-Sound.mp3",
        },
        { url: "img/FriendsPlus/Page75/E5/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page75/E5/9.jpg" }],
    ],
  },
};

export default json;
