import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB4-2023-U1-P11-E1",
    audio: "Audios/Page10/E3/audio-e3.mp3",
    video: "Videos/Unit 01.mp4",
    component: UI,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='10' src='Audios/Page10/E3/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page10/E3/1.jpg",
          audioUrl: "Audios/Page10/E3/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E3/2.jpg",
          audioUrl: "Audios/Page10/E3/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page10/E3/3.jpg",
          audioUrl: "Audios/Page10/E3/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page10/E3/4.jpg",
          audioUrl: "Audios/Page10/E3/hinh4.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB4-2023-U1-P11-E2",
    audio: "Audios/Page11/E2/audio-e2.mp3",
    video: "",
    component: UI,
    // recorder: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen to the story and repeat. <headphone name='11' src='Audios/Page11/E2/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page11/E2/1.jpg",
        },
      ],
      [
        { url: "img/FriendsPlus/Page11/E2/2.jpg" },
        {
          url: "img/FriendsPlus/Page11/E2/3.jpg",
          audioUrl: "Audios/Page11/E2/firefighters.mp3",
        },
        { url: "img/FriendsPlus/Page11/E2/4.jpg" },
        {
          url: "img/FriendsPlus/Page11/E2/5.jpg",
          audioUrl: "Audios/Page11/E2/doctors.mp3",
        },
        { url: "img/FriendsPlus/Page11/E2/6.jpg" },
        {
          url: "img/FriendsPlus/Page11/E2/7.jpg",
          audioUrl: "Audios/Page11/E2/teachers.mp3",
        },
        {
          url: "img/FriendsPlus/Page11/E2/8.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page11/E2/9.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB4-2023-U1-P11-E3",
    audio: "",
    video: "",
    component: D1,
    fontSize: 26,
    exerciseKey: "img/FriendsPlus/Page11/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/> ",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page11/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E3/2.jpg" },
        {
          url: "img/FriendsPlus/Page11/E3/3.jpg",
          input: true,
          answer: "We're pilots.",
        },
        { url: "img/FriendsPlus/Page11/E3/4.jpg" },
        {
          url: "img/FriendsPlus/Page11/E3/5.jpg",
          input: true,
          answer: "We're firefighters.",
        },
        { url: "img/FriendsPlus/Page11/E3/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page11/E3/7.jpg" }],
    ],
  },
  4: {
    unit: "Unit 1",
    id: "SB4-2023-U1-P11-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Look and say. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/> ",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page11/E4/1.jpg" }]],
  },
  5: {
    unit: "Unit 1",
    id: "SB4-2023-U1-P11-E5",
    // audio: "img/FriendsPlus/Page100/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [[{ url: "img/FriendsPlus/Page11/E5/1.jpg" }]],
  },
};

export default json;
