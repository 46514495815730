import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Select_Image from '../../components/ExcerciseTypes/Select/Select_Image';
const json = {
  1: {
    unit: "Starter",
    id: "SB4-2023-Starter-P5-E1",
    audio: "Audios/Page4/audio-e3.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='02' src='Audios/Page4/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page4/E3/1.jpg",
          audioUrl: "Audios/Page4/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page4/E3/2.jpg",
          audioUrl: "Audios/Page4/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page4/E3/3.jpg",
          audioUrl: "Audios/Page4/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page4/E3/4.jpg",
          audioUrl: "Audios/Page4/hinh4.mp3",
        },
      ],
      [
        {
          audioUrl: "Audios/Page4/hinh5.mp3",
          url: "img/FriendsPlus/Page4/E3/5.jpg",
        },
      ],
    ],
  },
  2: {
    unit: "Starter",
    id: "SB4-2023-Starter-P5-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page5/E2/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    inputSize: 35,
    titleQuestion: [
      {
        num: "2",
        title:
          "Read and write T (true) or F (false). <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style=' line-height:56px'>
            <b>1.</b> Rosy has brown hair. <u style='color:rgb(43 179 229)' >&ensp;T&ensp;</u><br>
            <b>2.</b> Tim is Rosy’s brother. #<br>
            <b>3.</b> Billy has curly hair. #<br>
            <b>4.</b> Billy is under the bed. #
          </div>
          
        
        `,
        answer: ["F", "T", "F"],
      },
    ],
  },
  3: {
    unit: "Starter",
    id: "SB4-2023-Starter-P5-E3",
    audio: "Audios/Page5/audio-e3.mp3",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page5/E3/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    hintBox:[{
      src:["mom","dad","brother","sister","grandma","grandpa","aunt","uncle","cousin",],
      width:950,
      borderColor:"black"
    }],

    stylesTextInput: {
      border: "1px solid black",
      height: "37px",
      fontWeight:"bold",
      borderRadius: "5px",
    },
    inputSize: 37,
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and write the numbers. <headphone name='03' src='Audios/Page5/tieude-e3.mp3'></headphone>  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='font-weight:bold'>
          <hintbox id='0'></hintbox>
        </div>
        <img style='width:90%' src='img/FriendsPlus/Page5/E3/1.jpg'/>
        <div style='position: relative;top:-743px'>
            <span style='position: absolute; top: 159px; left:143px'>#</span>
            <span style='position: absolute; top: 128px; left: 360px'>#</span>
            <span style='position: absolute; top: 138px; left: 468px'>#</span>
            <span style='position: absolute; top: 195px; left: 560px'>#</span>
            <span style='position: absolute; top: 175px; left: 702px'>#</span>
            <span style='position: absolute; top: 554px; left: 207px'>#</span>
            <span style='position: absolute; top: 588px; left: 322px'>#</span>
            <span style='position: absolute; top: 535px; left: 588px'>#</span>
          </div>
          
        
        `,
        answer: ["2", "6", "5", "7", "8", "4", "3", "9"],
      },
    ],
  },
  4: {
    unit: "Starter",
    id: "SB4-2023-Starter-P5-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "4",
        title:
          "Point and say. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page5/E4/1.jpg",
        },
      ],
    ],
  },
  
};

export default json;
