import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    unit: "Unit 10",
    id: "SB4-2023-U10-P81-E1",
    audio: "Audios/Page81/audio-e1.mp3",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page81/E1/Key/answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and match. What do they like? <headphone name='129' src='Audios/Page81/tieude-e1.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "74px",
              left: "232px",
              width: "133px",
              height: "137px",
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "74px",
              left: "415px",
              width: "133px",
              height: "137px",
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "74px",
              left: "601px",
              width: "133px",
              height: "137px",
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "74px",
              left: "786px",
              width: "133px",
              height: "137px",
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "240px",
              left: "78px",
              width: "158px",
              height: "108px",
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "240px",
              left: "353px",
              width: "158px",
              height: "108px",
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "240px",
              left: "627px",
              width: "158px",
              height: "108px",
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "240px",
              left: "902px",
              width: "158px",
              height: "108px",
              borderRadius: "10px",
              // border: "1px solid #2eb6e1",
              // background: "white",
            },
          }, // 7
        ],
        answers: ["0-5", "1-4", "2-6", "3-7"],
        initialValue: [],
      },
      Layout: `
      <div>
        <input id='0' type= 'boxMatch' />
        <input id='1' type= 'boxMatch' />
        <input id='2' type= 'boxMatch' />
        <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
        <input id='5' type= 'boxMatch' />
        <input id='6' type= 'boxMatch' />
        <input id='7' type= 'boxMatch' />

        </div>
        <img  src='img/FriendsPlus/Page81/E1/1.jpg'/>
      `,
    },
  },

  2: {
    unit: "Unit 10",
    id: "SB4-2023-U10-P81-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title: "Ask and answer. ",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page81/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 10",
    id: "SB4-2023-U10-P81-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3 ",
        title: "Talk about your family.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page81/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 10",
    id: "SB4-2023-U10-P81-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page81/E4/Key/answerKey.png",
    component: T6,
    maxLength: 7,
    textAlign: "center",
    inputSize: 120,
    // textareaStyle: { width: 800 },
    stylesTextInput: { background: "#c7eafe" },
    titleQuestion: [
      {
        num: "4",
        title:
          "Complete the sentences. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style=' position: relative; '>
          <img  src='img/FriendsPlus/Page81/E4/1.jpg'/>
          <div style=' position: absolute; top: 147px; left:41px; '>
            Hi! My#Chaya. I’m#, and I’m from Cambodia. I like #, but I don’t like#volleyball. Email me at chaya@mail.me.com. Write soon!
          </div>
        </div>
        
        `,
        answer: ["name's", "eight", "fishing", "playing"],
      },
    ],
  },
  5: {
    unit: "Unit 10",
    id: "SB4-2023-U10-P81-E5",
    audio: "",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "",
    component: T6,
    // maxLength: 7,
    inputSize: 40,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "5",
        title:
          "Write your online pen pal profile. Use the example to help you. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
